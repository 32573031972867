import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Promo from 'images/promo.jpg'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/5-reasons-why-you-should-download-and-sign-up-with-digipay`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '5 Reasons Why You Should Download and Sign Up With Digipay',
  },
  {
    property: 'og:description',
    content:
      'Here are 5 reasons why you should get started with Digipay today!',
  },
  {
    property: 'og:image',
    content: Promo,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet
      title="5 Reasons Why You Should Download & Sign Up w/ Digipay"
      meta={SITE_META}
    >
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Promo} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          5 REASONS WHY YOU SHOULD DOWNLOAD AND SIGN UP WITH DIGIPAY
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            The Tagline Says It All, ‘Mabilis. Madali. Mura’
          </Heading>
          <Text>
            ‘Mabilis. Madali. Mura.’ Your account will be activated within
            twenty-four (24) hours. DIGIPAY app is user-friendly and anyone can
            easily navigate the app.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            The Payment Is Like Noodles - Instant!
          </Heading>
          <Text>
            Your customers will pay upfront with cash after each transaction and
            you will not have to worry about declined credits cards or bounced
            checks. DIGIPAY’s notification will also give you that feeling of
            security after each successful transaction.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Be A Winner When It Comes To Billers
          </Heading>
          <Text>
            Accepting payments for over 300 merchants or billers! This includes
            power and utility companies, credit cards, consumer finance loans,
            airlines, schools and many more. Digipay offers hundreds of products
            ranging from e-load, e-pins, bills payment, money transfer, mobile
            money top-up, and microinsurance.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            Low...Low...Low Labor Cost
          </Heading>
          <Text>
            Manual payments cost more than electronic ones when it comes to
            labor. With DIGIPAY, no need to hire another person to process
            transactions. Aside from labor, DIGIPAY offers electronic
            transaction receipts via SMS or Email. You are not just saving money
            but you are also saving the environment.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            It’s All About Additional Income
          </Heading>
          <Text>
            Earn extra income from your DIGIPAY transactions. Get rebates or
            commission for every transaction. The more transactions, the more
            income you will have. So whether you have an existing business
            (sari-sari store), thinking of how to earn extra income, or want to
            start your own business, DIGIPAY can give you that opportunity you
            have been waiting for.
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
